<template>
  <div class="app auth-page" id="auth" :style="authStyle">
    <div class="container py-5">
      <div class="row">
        <div class="col-12">
          <div class="card card-container">
            <h5>{{ 'settings.changeYourPassword' | translate }}</h5>
            <form class="form-register" @submit.prevent="changePassword">
              <span id="reauth-email" class="reauth-email"></span>
              <password-input v-model="user.password" @isValid="(valid) => (isValidPassword = valid)" />
              <input
                type="password"
                id="inputConfirmPassword"
                class="form-control mt-1"
                :placeholder="$t('form.confirmPasswordPlaceholder')"
                required
                v-model="user.confirmPassword"
              />
              <br />

              <template v-if="changedPasswordThroughProfile">
                <span class="my-2 text-muted">{{ 'form.enterCurrentPasswordText' | translate }}</span>
                <input
                  type="password"
                  id="inputConfirmPassword"
                  class="form-control"
                  :placeholder="$t('form.currentPasswordPlaceholder')"
                  required
                  v-model="user.oldPassword"
                />
              </template>
              <primary-button
                :disabled="!isValid"
                :loading="loading"
                size="large"
                class="btn-block btn-signin mt-2"
                type="submit"
                >{{ 'general.save' | translate }}</primary-button
              >
            </form>
            <br />
            <router-link class="auth-link" to="/profile">{{ 'general.back' | translate }}</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import authMixin from '../../mixins/auth';
import { mapGetters } from 'vuex';
import notifications from '@shared/mixins/notifications';
import cloneDeep from 'lodash/cloneDeep';

export default {
  mixins: [authMixin, notifications],
  created() {
    if (!this.$route.query.RequestString && !this.changedPasswordThroughProfile) {
      this.showErrorNotification(null, this.$t('notifications.changePasswordError'));
      this.$router.push('login');
    }
  },
  computed: {
    isValid() {
      if (this.changePasswordThroughProfile) {
        return this.user.password != '' && this.user.confirmPassword != '' && this.user.oldPassword != '';
      } else {
        return this.user.password != '' && this.user.confirmPassword != '';
      }
    },
    changedPasswordThroughProfile() {
      return this.authenticated;
    },
    ...mapGetters('auth', ['authenticated'])
  },
  data() {
    return {
      loading: false,
      user: {},
      isValidPassword: false
    };
  },
  methods: {
    passwordChangeSuccess() {
      this.showSuccessNotification(this.$t('notifications.updatedPassword'));
      this.loading = false;
      if (this.changedPasswordThroughProfile) {
        this.$router.push({ name: 'profile' });
      } else {
        this.$router.push({ path: '/auth/login' });
      }
    },
    changePassword() {
      if (this.user.password !== this.user.confirmPassword) {
        return this.showErrorNotification(null, this.$t('notifications.passwordsDontMatch'));
      } else if (!this.isValidPassword) {
        return this.$notify({
          type: 'error',
          message: this.$t('password.passwordFormatIncorrect'),
          position: 'bottom-right'
        });
      }
      this.loading = true;
      if (this.changedPasswordThroughProfile) {
        let user = {
          ...this.$store.getters['auth/user'],
          ...this.user,
          subdomain: this.$store.getters['settings/subdomain']
        };
        user.newPassword = cloneDeep(this.user.password);
        user.password = cloneDeep(this.user.oldPassword);

        this.$http.put('profile', user).then(this.passwordChangeSuccess(), (error) => {
          const { response } = error;
          if (error && error.error === 'Current password is incorrect') {
            this.showErrorNotification(error, this.$t('notifications.errorCurrentPasswordIncorrect'));
          } else if (response && response.data && response.data.errors && response.data.errors.length > -1) {
            let errorToShown = response.data.errors[0];
            if (errorToShown === 'Entered password was chosen in recent history.')
              this.showErrorNotification(error, this.$t('notifications.enterPasswordChosenRecently'));
          } else {
            this.showErrorNotification(error, this.$t('notifications.error'));
          }
          this.loading = false;
        });
      } else {
        this.$http
          .post('changepassword', {
            ...this.user,
            requestString: this.$route.query.RequestString
          })
          .then(
            (response) => this.passwordChangeSuccess(),
            (error) => {
              if (error && error.response.data.errors) {
                error = error.response.data.errors[0];
                if (error === 'Entered password was chosen in recent history.') {
                  error = {};
                  error.error = this.$t('notifications.enterPasswordChosenRecently');
                }
              }
              this.showErrorNotification(error, this.$t('notifications.changePasswordError'));
              this.loading = false;
            }
          );
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import './auth.scss';
</style>
